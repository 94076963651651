<template>
  <button
    @click="click"
    type="button"
    :class="`button_common${type ? type : ''} ${disabled ? 'disabled' : ''}`"
    :disabled="disabled"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: ["text", "type", "disabled"],
  name: "Button",
  // computed: {
  //   template() {
  //     return process.env.VUE_APP_TYPE.toLowerCase();
  //   },
  // },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="page">
    <div class="">
      <div class="page__title_main not-margin">
        Спасибо, ваши данные <br />
        успешно изменены!
      </div>
      <label class="page__title_desc">
        С заботой о наших любимых питомцах
      </label>
      <img :src="logoUrlComputed" class="finish-image" height="80%" width="80%" />
    </div>
    <a class="marginTop" :href="`/${uuid}`">
      <Button text="Перейти к питомцу"></Button>
    </a>
  </div>
</template>

<script>
import Logo from "@/assets/FourLapsFinish.png";
import Button from "@/components/common/Button.vue";

export default {
  name: "RedactFinish",
  components: {Button},
  data() {
    return {
      uuid: "",
    };
  },
  mounted() {
    this.uuid = this.$route.query.uuid;
  },
  computed: {
    logoUrlComputed() {
      return Logo;
    },
  },
};
</script>

<style scoped lang="scss">
.fourlaps-page-finish {
  display: flex;
  align-items: center;
  justify-content: center;
}
.marginTop{
  margin-top: 68px;
}
.finish-image{
  object-fit: contain;
  margin-top: 10px;
}
</style>
